@import url("./css/icons/icofont/icofont.min.css");

@import url("./css/module/numpad.css");
@import url("./css/module/loading.css");
@import url("./css/print.css");
/* // Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... } */


body {
    overflow-x: hidden;
    
}

body:has(.modal-backdrop.show) {
    overflow-y: hidden;
}

.float-right {
    float: right;
}

img {
    border-radius: 0.5rem;
}

.link {
    color: var(--cui-link-color);
    cursor: pointer;
    text-decoration: underline;
}

.link:hover {
    text-decoration: none;
}


.text-right {
    text-align: right;
}

button.btn svg {
    margin-right: 0.25rem;
}


@media only screen and (max-width: 768px) {
    .header-brand  img {
        height: 30px;
    }
  }


/* custom css for react-bootstrap-table-next */
@media only screen and (max-width: 768px) {
    .react-bootstrap-table  {
      margin-top: 0.5rem
    }
  }

.react-bootstrap-table {
    margin-bottom: 1rem;
}

.react-bootstrap-table thead th { position: sticky; top: -1px; }

.react-bootstrap-table .total td, .react-bootstrap-table .total th  {     
    position: sticky !important;
    top: 60px;
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color:rgb(255, 255, 224);
    border-bottom: 1px solid #ddd;
  }

  .react-bootstrap-table tbody th {
    background-color: #fafafa;
    position: sticky !important;
    top: 38px;
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-bottom: 1px solid #ddd;
  }

.react-bootstrap-table tfoot td, .react-bootstrap-table tfoot th {     
    position: sticky;
    bottom: 0px;
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color:rgb(255, 255, 224) !important;
    border-bottom: 1px solid #ddd;
  }

.react-bootstrap-table-pagination .pagination  { overflow-x: auto; padding: 0.5rem}
.react-bootstrap-table .sortable {
    transition: 0.25s;
}

.react-bootstrap-table-pagination {
    padding-bottom: 0.5rem;
}

.react-bootstrap-table .sortable, .custom-table .sortable{
   cursor: pointer;
}

.react-bootstrap-table .sortable svg {
    width: 0.65rem !important;
    height: 0.65rem !important;
}

.react-bootstrap-table tr {
   background-color: #fff;
}

.react-bootstrap-table .sortable:hover, .custom-table .sortable:hover {
    background-color: #333;
    color: #fff
}

.react-bs-table-sizePerPage-dropdown ul.dropdown-menu  {
    position: absolute;
    left: 3rem;
    top: 0;
}

.react-bootstrap-table {
    overflow-x: auto;
}

@media only screen and (max-width: 992px) {
    .react-bootstrap-table.md .table {
        min-width: 768px;
    }
    
    .react-bootstrap-table.lg .table {
        min-width: 992px;
    }
    
    .react-bootstrap-table.xl .table {
        min-width: 1200px;
    }
  }



.react-bootstrap-table .selection-cell-header,
.react-bootstrap-table .selection-cell {
    width: 5%;
    text-align: center;
    vertical-align: middle
}

@media only screen and (min-width: 968px) {
    .limit-height {
        max-height: calc(100vh - 150px);
        overflow: auto;
    }
    
    .limit-height-inventory {
        max-height: calc(100vh - 200px);
        overflow: auto;
    }
    
    .permission-wrapper {
        max-height: calc(100vh - 250px);
        overflow: auto;
    }
    
    .balance-sheet-table-wrapper {
        max-height: calc(100vh - 350px);
    }
}

@media only screen and (max-width: 968px) {
    /* .limit-height {
        max-height: calc(100vh - 150px);
        overflow: auto;
    }
    
    .limit-height-inventory {
        max-height: calc(100vh - 200px);
        overflow: hidden;
    }

    .balance-sheet-table-wrapper {
        max-height: calc(100vh - 200px);
    } */
}



.react-bootstrap-table .barcode svg {
    transform: none !important;
}

.react-bootstrap-table thead th {
    text-transform: capitalize;
    z-index: 1000;
}

.react-bootstrap-table .filter-label {
    width: 100%;
}

/* page loading */
.loading-backdrop {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0px;
    top: 0;
    background: transparent;
    opacity: 1;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%
  }

  /* empty */
  .empty .empty--icon {
    margin-right: 1rem;
    width: 1.15rem !important;
    height: 1.15rem !important;
  }

  /* list */
  .list-inventory strong {
    display: block;
  }

  .list-description {
    width: 100%;
  }

  .list-description strong {
    line-height: 2.5;
  }


  /* spin */
.loading-overlay {
    position: relative;
  
}
.loading-icon {
    position: absolute;
    z-index: 1;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    right: 0;
    left: 0;
    top: 10%;
    bottom: 0;
}

.loading-body.active {
    opacity: 0.1;
    pointer-events: none;
}

/* scan camera */
.viewport video {
    width: 100%;
}

.viewport canvas {
   display: none;
}

/* FIX SIDENAV MOBILE */
.sidebar  {
    /* z-index: 2000 !important; */
}

.sidebar-backdrop.fade:not(.show) {
    z-index: -1;
}

.bar-code svg {
    max-width: 100%;
}

.sidebar-avatar img {
    max-width: 32px;
}

.sidebar.has-unfoldable .sidebar-nav {
    overflow-y: hidden !important ;
}

.sidebar.has-unfoldable:hover .sidebar-nav {
    overflow-y: auto !important ;
}


/* MENU  */
.sidebar-nav .nav-link {
    text-transform: capitalize;
}

.nav-group.auto-visible .nav-group-items {
    display: block !important;
    height: auto !important;
}

.nav-group.auto-visible.show .nav-group-items {
    display: none !important;
    height: 0px !important;
}

/* TRUCK CART ICON */
.my-truck-icon {
    position: relative;
}

.my-truck-icon::before {
    font-size: 1.5rem;
}

.my-truck-icon .badge {
    top: -5px !important;
    font-size: 0.7rem;
}



/* STICKY AREA */
.top-sticky {
    position: sticky;
    height: 60px;
    background-color: #fff;
    right: 0;
    left: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    z-index: 1001;
    border-top: 1px solid #ddd;
    bottom: 0;
    overflow: auto;
}


/* SCROLLBAR CUSTOM */

/* @media only screen and (min-width: 992px) {
   
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #3c4b64;
    }
} */



.h-w-full {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.rbt .dropdown-menu.show {
    z-index: 1020;
}

 .square-note {
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    
 }

 /* BARCODE */
 .barcode svg {
    max-width: 100%;
 }

 @media only screen and (max-width: 576px) { 

    .flex-xs-column-reverse {
        flex-direction: column-reverse;
    }

    .font-size-sm-small {
        font-size: 0.8rem;
    }

 }
 
 .btn.btn-danger {
    color: #fff;
 }

 /* TOP CONFIRM */
 .modal-content, .offcanvas-body {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
 }

  /* LIST HORIZONTAL */
ul.horizontal-list-custom {
    list-style: none;
    display: inline-block;
    padding: 0;
  }
  
  ul.horizontal-list-custom li {
    display: inline-block;
    padding: 0 0.25rem;
    cursor: pointer;
  }
  
  /* ROW INLINE */
  .row.row-inline {
    flex-wrap: nowrap !important;
    overflow: auto;
  }

  .two-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }