$base-color: #321fdb;
$secondary-color: gray;

.stepper-container {
    padding: 0.375rem 0.5rem;

    .stepper--header {
        display: flex;
        align-items: center;

        .header__title {
            width: 80%;
            font-size: 1.75rem;
            color: $base-color;
            margin-bottom: 15px;
            font-weight: normal;
            text-align: left;
            font-weight: 550;
        }

        .required-text {
            font-size: 0.9rem;
        }

        .header__progress {
            width: 20%;
            font-size: 1.75rem;
            color: $secondary-color;
            margin-bottom: 10px;
            font-weight: normal;
            text-align: right;
        }
    }
}