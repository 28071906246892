.for-print {
  font-size: 12px !important;
}

.table-print {
    border-color: #000;
}

.table-header {
  border: 1px solid #000;
  width: 100%;
  font-size: 24px;
  font-weight: 650;
}


@media only screen and (max-width: 768px) {
  
  .table-print tbody {
    min-width: 1200px;
  }

  .table-print {
    overflow-x: auto;
  }
}

.table-print thead {
    color: #000;
    background-color: #ddd;
}

.table-print svg {
  max-width: 100px !important;
  height: 25px !important;
}

@page {
  size: A4;
  margin: 0;
  /* this affects the margin in the printer settings */ 
  margin: 10mm 10mm 10mm 10mm;  
}

@media print {
    * {
      font-size: 12px;
    }

    .footer-total-print  {
      text-align: center;
    }

    .footer-total-print strong  {
      display: block;
    }
  
  
    .print{
      page-break-after: avoid;
    }

    .break-after {page-break-after: always;}
    .break-after-avoid {page-break-after: avoid;}
    .auto-break{page-break-inside:avoid; page-break-after:auto; position:relative;
      display:block;}


    .table-print {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }

    .header, .breadcrumb, .print-action, .sidebar, .btn-to-top  {
        display: none;
    }

    .table-responsive {
      overflow: hidden;
    }

    html:not([dir=rtl]) .wrapper {
      padding-left: 0 !important;
    }
    
    .for-print {
      display: block !important;
    }

   .col-print-4 {
     width: 30%;
   }

   .col-print-8 {
     width: 70%;
   }

   .col-print-5 {
     width: 41.67%;
   }

   .col-print-6 {
     width: 50%;
   }

   .col-print-7 {
     width: 58.33%;
   }

   .col-new-print-1 {
    width: 8.33%;
   }

   .col-new-print-2 {
    width: 16.66%;
   }

   .col-new-print-3 {
    width: 25%;
   }

   .col-new-print-4 {
    width: 33.33%;
   }

   .col-new-print-5 {
    width: 41.67%;
   }

   .col-new-print-6 {
    width: 50%;
   }

   .col-new-print-7 {
    width: 58.33%;
   }

   .col-new-print-8 {
    width: 66.67%;
   }

   .col-new-print-9 {
    width: 75%;
   }

   .col-new-print-10 {
    width: 83.33%;
   }

   .col-new-print-11 {
    width: 91.67%;
   }

   .col-new-print-12 {
    width: 100%;
   }

   .d-print-none {
    display: none !important;
   }

   .recharts-legend-wrapper {
    text-align: center;
    width: 100% !important;
    position: relative;
    margin: 0;
    height: 50px;
   }

  
 }
 