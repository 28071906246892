
.popover-body {
    padding: 0.5rem 0;
}

.keyboard {
    overflow: auto;
}

.numpad-container {  
    margin: 0;  
    list-style: none;  
    padding: 5px 22px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 5px 5px; 
}  

.numpad-container li.letter {  
    float: left;  
    margin: 0 5px 5px 0;  
    min-width: 50px;
    width: 100%;  
    height: 50px;  
    font-size: 20px;
    line-height: 60px;  
    text-align: center;  
    background: #f6f6f6;  
    border: 1px solid #ddd;  
    border-radius: 5px;  
    display: flex;
    justify-content: center;
    align-items: center;
}  

li.letter.disabled {
    background: #fff;
    opacity: 0.7;
}


.lastitem {  
    margin-right: 0;  
}  

.uppercase {  
    text-transform: uppercase;  
}  

.numpad-container .space {  
    float: left;
    width: 556px;  
}  

.numpad-container .switch, .numpad-container .space, .numpad-container .return{
    font-size: 16px;
}

.on {  
    display: none;  
}  

.numpad-container li:not(.disabled):hover {  
    position: relative;  
    top: 1px;  
    left: 1px;  
    border-color: #e5e5e5;  
    cursor: pointer;  
}  

.keyboard .input-number {
    font-size: 1.75rem;
    padding: 0 20px;
    min-height: 50px;
}

.keyboard .input-number .top-input {
    font-size: 1.75rem;
    padding: 0 20px;
    min-height: 50px;
    border: 0
}

.keyboard .input-number .top-input:focus {
    box-shadow: none;
}

.keyboard .edit-btn span {
    padding: 0 20px;
}

