// Here you can add other styles

body {
    @media all and (max-width: 576px) {
        font-size: 16px;
    }
    &:has(.loading-backdrop) {
        overflow: hidden;
    }
}

.btn-link {
    text-decoration: none !important;
}

// FORM VALIDATION WITH *
// form.needs-validation {

//     &:has(input:required) label.form-label::after,
//     &:has(textarea:required) label.form-label::after
//    {
//         content: '*';
//         margin-left: 0.5rem;
//         color: var(--cui-red);
//         font-size: 1.1rem;
//         position: absolute;
      
//     }

//     input:required::placeholder,
//     textarea:required::placeholder  {
//         color: var(--cui-red);
//     }
    
// }

// REACT TABLE
.react-bootstrap-table {
    
    table {
        table-layout: fixed;
    }

    tbody > tr > td {
        min-height: 40px;
    }

    tbody.body-with-barcode > tr {
        min-height: 100px;
        height: 100px;
    }

    tbody > tr > td, tbody > tr > th {
        position: relative;
        overflow: auto;

        &.barcode {
            svg {
                height: 35px !important;
            }
        }
    }

    .editable {
        border-bottom: 1px dotted;
        color:#39f;

        .edit-icon {
            position: absolute;
            top: 2.5px;
            right: 2.5px;
            font-size: 0.65rem;
            color: #b1b7c1;
        }
    }

  
}

.react-bootstrap-table-pagination {
    align-items: center;
    @media all and (min-width: 992px) {
        // display: none;
    }
    
    .dropdown-menu {
        z-index: 1002;
    }
}



.react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: end;
}


.react-bootstrap-table-pagination {
    font-size: 0.75rem;
    button {
        font-size: 0.75rem;
    }
}

.react-bootstrap-table {
    th[data-row-selection="true"] {
        width: 50px;
    }
}

.editable {
    border-bottom: 1px dotted;
    color:#39f;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}

.table-mobile {
    font-size: 14px;

    button.btn-link {
        text-align: left;
        font-size: 0.9rem;
        padding: 0;
    }

    @media only screen and (max-width: 576px) { 
        .row {
            
            .col-table {
                padding-bottom: 0.25rem;

                .card-body {
                    padding: 0.25rem;
                }
            }
        }
    }
    @media only screen and (min-width: 577px) { 
        .row {
            align-items: stretch;
    
            .col-table {
                padding-left: 0.1rem;
                padding-right: 0.1rem;
                padding-bottom: 0.2rem;

                .card-body {
                    padding: 0.3rem;
                }
            }
        }
    }
    
}

.truck-choose-list {
    tr {
        height: 55px !important;
    }
}
// CARD PAYMENTS
.check-payment {
    label {
        width: 100%;
        cursor: pointer;
    }

    .form-check-input {
        display: none;

        &:checked ~ label {
            .card-payment {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                background-color: var(--cui-blue);
        
                i {
                   color: #fff;
                }
            
                .payment--title {
                    color: #fff;
                }
            }
        }
    }

}

.card-payment {
  
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.75rem;
    position: relative;
    background-color: #fafafa;
    height: 100%;
    

    i {
        font-size: 2.5rem;
        display: block;
    }

    .payment--title {
        font-size: 1.25rem;
    }

    .card-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20px;
        background-color: #333;
    }


}

.select-payment-row {
    align-items: stretch;

    .form-check-label, .form-check {
        height: 100%;
    }
}

// TRUCK CART TABLE
@media only screen and (min-width: 993px) {
    .offcanvas.add-invoice-item .react-bootstrap-table {
        max-height: 450px;
    }
}

.offcanvas {
    z-index: 1060 !important;
}


.balance-sheet-table-wrapper {
    tbody  {
        .load-out {
            background-color: #fffee0;
        }

        .load-in {
            background-color: #f0f8ff;
        }

        .customer-return {
            background-color: #CCE7C9;
        }

        .warehouse-return {
            background-color: #FEF8E6;
        }

        .invoice-item {
            background-color: #e8f8ee;
        }

        .loss-column {
            background-color: #f1f1f1;
        }
    } 
}

// IMAGE PREVIEW

.image-preview {
    .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: #000015;
    }

    .close {
        position: absolute;
        text-decoration: none;
        font-size: 1.5rem;
        color: red;
        left: 50px;
        top: 50px;
        font-weight: bold;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    }
}

.tool-item {
    margin: 5px;

    &:first-child{
        margin-left: 0;
    }

    @media only screen and (max-width: 576px) {
        min-width: none !important;
        white-space: nowrap;
    }
}


.tool-description {
    // @media only screen and (min-width: 1200px) {
        display: flex;
        align-items: stretch;
        overflow: auto;
    
        i {
            display: block;
        }
    
        .tool-item {
            margin: 0
        }
        
        span {
            margin:0 5px;
        }
    
        .btn {
            // width: 100%;
            height: 100%;
            width: 100px;
        }
    // }
   
}

.tools {
    display: flex;
    margin-top: 0.5rem;
    overflow: auto;

    .tool-item {
        width: auto;
        margin: 2.5px;
    }
  

    @media only screen and (max-width: 768px) {
        .tool-item {
            width: 33.33%;
        }

        .tool-item:first-child {
            margin-left: 0px;
        }

        .btn {
            width: 100%;
            height: 100%;

            i {
                display: block;
            }
        }
    }

}

.rbt-aux {

    position: absolute;
    top: 2.5px;
    right: 5px;
    .rbt-close-content {
        display: none;
    }
}

.delivery--statistic  {
    .text-uppercase {
        white-space: nowrap;
    }

    .warning {
        position: absolute;
        right: 10px;
        top: 20%;
        font-size: 2.5rem;
    }

    .card-footer {
        padding: 0;

        .btn {
            border-radius: 0;
        }
    }
}

.load-in-tools {
    .btn {
        width: 100%;
        height: 100%;
        text-align: center;

        span {
            padding: 0 !important;
        }

        @media only screen and (max-width: 576px) {
            i {
                display: block;
            }
        }

     
    }
}

@media only screen and (max-width: 992px) {
    .btn-to-top {
      position: fixed;
      bottom: 10%;
      right: 20px;
      z-index: 99;
      border-radius: 4px;
    }
  }

  .scroll-area {
    overflow: auto;
    overflow-x: hidden;
    height: 400px;
    position: relative;
    padding: 0.375rem 0;
    padding: 0.25rem 0.5rem;

    .scroll--direction {
        position: sticky;
        right: 20px;
        z-index: 1000;
        text-align: right;
        padding-right: 5px;
        top: 40%;
        height: 0;

        button {
            margin-top: 1rem;
        }
    }

    

    .scroll--direction__up {
        width: 100%;
        position: sticky;
        top: -5px;
        z-index: 999;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        button {
            border-radius: 0;
        }
    }

    .scroll--direction__down {
        width: 100%;
        position: sticky;
        bottom: -5px;
        z-index: 999;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

        button {
            border-radius: 0;
        }
    }

  }

@media only screen and (max-width: 576px) {
  .nav.nav-tabs {
    font-size: 15px;
    flex-wrap: inherit !important;
    overflow-x: auto;
    overflow-y: hidden;

    .nav-link {
        white-space: pre;
    }
  }
}

.list-simple-item > .list-group-item {
    display: flex;
    align-items: center;

    &.list-header {
        font-weight: 400 !important;
        color: grey;
        background-color: transparent;
        padding: 0.5rem 0;
    }

    @media only screen and (max-width: 600px) {
        .item-name {
            width: 50%;
        }
        .item-quantity {
            width: 20%;
            text-align: center;
        }
        .total-price {
            width: 30%;
            text-align: right;
        }
    }

    @media only screen and (min-width: 601px) {
        .item-name {
            width: 60%;
        }
        .item-quantity {
            width: 20%;
            text-align: center;
        }
        .total-price {
            width: 20%;
            text-align: right;
        }
    }


}


.list-group-horizontal-md.list-description.list-request-order {
    @media only screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        .list-group-item {
            border: 0
        }
    }

    @media only screen and (min-width: 577px) and (max-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        .list-group-item {
            border: 0
        }
    }

    @media only screen and (max-width: 576px) {
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr;

        .list-group-item {
            border: 0
        }
    }

   
}

.tool-header {
    .btn {
        margin: 0 5px;
    }

    @media only screen and (max-width: 576px) {
        display: flex;
        
        .popconfirm-trigger {
            display: contents;
            width: 100%;
            height: 100%;
        }
    }
}

.input-group  { label {display: none;}}

.top-gallery {
    img {
        height: 75px;
        width: 75px;
        object-fit: contain;
    }
}

.one-line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 95%;

    // &:hover {
    //     white-space: inherit;
    // }
}

tr.one-line:hover {
    white-space: nowrap;
    &.no-hover {
        white-space: inherit;
    }
}



.top-fixed {
    position: fixed;
    background: #fff;
    top: 65px;
    left: 0;
    right: 0;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1000;
}

.widget-f-collapse {
    position: relative;


    &.hide-warning {
        .warning {
            display: none;
        }
    }

    .card {
        width: 80px;
        overflow: hidden;
    }

    .icon-btn {
        color: red;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        right: 100px;
        top: 40%;
        font-size: 1.25rem;
        cursor: pointer;

        &:hover{
            background-color: #ddd;
        }
    }
}

.col-switch {
    .form-switch{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.sigCanvas {
    background-color: #FFF7D1;
}

.single-line {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-left {
    text-align: left;
}

@media only screen and (max-width: 992px) {
   .pl-md-0 {
    padding-left: 0;
   }
}

.trial-icon {
    position: fixed;
    right: -10px;
    top: 50%;
    z-index: 9999;
}

.form-switch.color-success {
    .form-check-input:checked {
        background-color: green !important;
        border-color: green !important;
    }
}

.grid-1-row {
    grid-template-rows: 1fr !important;
}

@media only screen and (min-width: 1200px) {

    ::-webkit-scrollbar {
        width: 7.5px;
        height: 7.5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ddd; 
        border-radius: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #777; 
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #999; 
    }

    // Scrollbar for desktop
    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    body::-webkit-scrollbar-track-piece {
        background-clip: padding-box;
        background-color: #f5f5f5;
        border: solid #fff;
        border-width: 0 0 0 3px;
        box-shadow: inset 1px 0 0 rgba(0,0,0,.14),inset -1px 0 0 rgba(0,0,0,.07)
    }

    body::-webkit-scrollbar-track-piece:horizontal {
        border-width: 3px 0 0;
        box-shadow: inset 0 1px 0 rgba(0,0,0,.14),inset 0 -1px 0 rgba(0,0,0,.07)
    }

    body::-webkit-scrollbar-thumb {
        border-width: 1px 1px 1px 5px;
    }

    body::-webkit-scrollbar-thumb:horizontal {
        border-width: 5px 1px 1px
    }

    body::-webkit-scrollbar-corner {
        background-clip: padding-box;
        background-color:#f5f5f5; 
        border:solid #fff; 
        border-width:3px 0 0 3px; 
        box-shadow:inset 1px 1px 0 rgba(0,0,0,.14); 
    }
}

.rcs-inner-container {
    scrollbar-color: #777;
}


.react-datepicker-popper, .popover-price {
    z-index: 9999 !important;
}

.popover-price {
    .popover-body {
        max-height: 300px;
        overflow-y: auto;
        padding: 0;
        margin-top: -10px;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.limit-height-subtract-300 {
    max-height: calc(100vh - 300px) !important;
}

.popover-price .popover-body {
    overflow-x: hidden;
}

.invoice-payment {
    max-height: 500px;
    overflow: auto;
}

.sticky-top {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding-bottom: 5px;
}

.text-eclipse {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 95%;
}

.sticky-col-table {
    border-collapse: separate;
    background-color: transparent;
    border-spacing: 0;

    & tbody .col-sticky {
        position: sticky;
        left: -1px;
        z-index: 1000;
        background-color: inherit !important;
        // box-shadow: inset -2px 0 4px -2px rgba(0, 0, 0, 0.4);
        border: 0;

        &::after {
            content: "";
            position: absolute;
            outline: 1px solid #ddd;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    tfoot .col-sticky, thead .col-sticky{
        position: sticky;
        left: -1px;
        z-index: 1010 !important;
        outline: 1px solid #ddd;
    }
}

.load-in-to-truck {
    .react-bootstrap-table{
        &.limit-height-inventory {
            max-height: calc(100vh - 400px) !important;
        }

        max-height: calc(100vh - 300px) !important;
    }
}